$(function() {
  var wid = $(window).width();
  if (wid < 640) {
    $('.imgChange').each(function() {
      $(this).attr(
        'src',
        $(this)
          .attr('src')
          .replace('_pc', '_sp')
      );
    });
  }
});

// video.js
let ready = (callbackFunc) => {
  if (document.readyState !== 'loading') {
    callbackFunc();
  } else {
    document.addEventListener('DOMContentLoaded', callbackFunc);
  }
};
